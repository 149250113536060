.footer {
    background-color: lightgray;
    padding: 30px 0;
    color: black; 
}

.footer h5 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: black; 
}

.footer a {
    color: black;
    transition: color 0.3s;
}

.footer a:hover {
    color: var(--btn-hover-color);
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
}

.footer ul {
    padding-left: 0;
}

.footer ul li {
    list-style-type: none;
    margin-bottom: 10px;
}

.footer-title {
    font-family: 'Kristen ITC', 'cursive';
    margin-bottom: 25px;
    color: #FFFFFF;
    padding-bottom: 12px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.footer-logo {
    border-radius: 3px;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    padding-top: 25px;
    max-width: 320px; /* adjust as needed to control the size of the logo */
    max-height: 100px;
    height: auto;
    margin-right: 10px;
}
.footer p {
    font-size: 14px; /* Smaller Paragraph Font Size */
    line-height: 1.5; /* Tighter line spacing */
    margin-bottom: 8px; /* Reduced space between paragraphs */
}
.social-icon {
    margin-top:20px;
    font-size: 15px !important;
}
.footer-legal {
    color:rgba(0, 0, 0, 0.5);
}
