.testimonial-container {
    background-color: #f4f4f4;
    max-height: 350px;
}

.testimonial-title {
    color: #333;
}

.testimonial-text {
    font-style: italic;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
}

.testimonial-button:hover {
    background-color: #0056b3;
}
