/* General styling for the contact section */
.contact {
    padding: 60px 0; /* Top and bottom padding for breathing space */
    width: 100%; /* Ensure it spans the full width */
}

/* Styling for the title */
.contact h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #333; /* Dark text for emphasis */
    margin-bottom: 20px; /* Space below the title */
    text-align: center; /* Center-align the title */
}

/* Styling for the description */
.contact p {
    font-size: 1.2rem;
    color: #777; /* Slightly lighter text for the description */
    margin-bottom: 40px; /* Space below the description and above the button */
    text-align: center; /* Center-align the description */
}

/* Styling for the button */
.contact .btn-primary {
    font-size: 1.2rem;
    padding: 15px 30px; /* Button padding for a larger, more clickable area */
    background-color: #FF5A5A;
    border: none;
    border-radius: 5px; /* Rounded corners for the button */
    transition: background-color 0.3s ease; /* Transition effect for smooth color change */
    display: block; /* Making the button a block-level element */
    margin: 0 auto; /* Auto margins to center the button */
}

.contact .btn-primary:hover {
    background-color: #d61b1b; /* Darker shade when hovered */
}
