.banner {
    position: relative;
    height: 500px; /* This will be our banner's fixed height on larger screens */
    max-height: 90vh; /* This ensures the banner won't exceed 90% of the viewport height */
    overflow: visible;
    margin-top:60px;
    color: white;
    padding: 0;
    margin-bottom: 100px;
}

.banner::after {
    content: ''; /* Necessary for the pseudo-element */
    position: absolute; /* Absolutely position this element relative to its parent (.banner) */
    top: 0; /* Position from the top of the parent */
    left: 0; /* Position from the left of the parent */
    right: 0; /* Position from the right of the parent */
    bottom: 0; /* Position from the bottom of the parent */
    background-color: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
    z-index: 2; /* Ensures the overlay is above the image but below the text */
}


.img-fluid-banner {
    width: 100%;       /* Ensures the image takes full width of the container */
    height: 100%;      /* Ensures the image takes full height of the container */
    object-fit: cover; /* Cover the container with the image without distorting it */
    overflow: hidden;  /* Hide any overflow caused by increasing the height */
}

.text-content {
    left: 5%;
    max-width: 80%;
    z-index: 3;
    
}

.banner-title,
.banner-description {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.banner-title {
    font-size: 5rem;
    line-height: 1.2;
    margin-bottom: 20px;
}

.banner-description {
    font-size: 1.5rem;
    line-height: 1.4;
}

.overlay-text {
    background-color: white;
    padding: 20px;
    width: 80%; /* Changed from max-width to width for consistency */
    position: absolute; /* Ensures we can position it relative to the banner */
    bottom: -100px; /* Increase negative value to show more of the element below the banner */
    left: 10%; /* Adjusted for alignment */
    z-index: 4;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Kristen ITC', 'cursive';
}

/* Adjust font color for better visibility on a white background */
.overlay-text p {
    color: black;
    line-height: 1.6;
    margin: 0; /* Remove any default margin */
}

@media screen and (max-width: 1133px) {
    .overlay-text {
        bottom: -170px;
    }
    
    .banner {
        margin-bottom: 170px;
    }
}

@media screen and (max-width: 1000px) {
    .overlay-text {
        bottom: -230px;
    }
    
    .banner {
        margin-bottom: 230px;
    }
}


@media screen and (max-width: 890px) {

    .overlay-text {
        max-width: 90%; /* More width for smaller screens */
        padding: 15px; /* Slightly less padding for smaller screens */
    }

    .banner {
        max-height: 600px;;
    }
    
    .text-content {
        text-align: left;
    }

    .banner-title {
        font-size: 4rem;
    }

    .banner-description {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 623px) {
    .overlay-text {
        bottom: -270px;
    }
    
    .banner {
        margin-bottom: 270px;
    }
}
@media screen and (max-width: 520px) {
    .overlay-text {
        bottom: -350px;
    }
    
    .banner {
        margin-bottom: 350px;
    }

    .banner-title {
        font-size: 3.7rem;
    }
}
@media screen and (max-width: 410px) {
    .overlay-text {
        bottom: -550px;
    }
    
    .banner {
        margin-bottom: 550px;
    }
    .banner-title {
        font-size: 3rem;
    }
}

@media screen and (max-width: 320px) {
    .overlay-text {
        bottom: -550px;
    }
    
    .banner {
        margin-bottom: 550px;
    }
    .banner-title {
        font-size: 2.7rem;
    }
}
@media screen and (max-width: 302px) {
    .overlay-text {
        bottom: -620px;
    }
    
    .banner {
        margin-bottom: 620px;
    }
    .banner-title {
        font-size: 2.5rem;
    }
}