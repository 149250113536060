.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 5%;
    background-color: var(--navbar-bg);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0px 3px 10px var(--box-shadow-color);
    overflow: visible;
}

.nav-logo h2 {
    color: var(--text-color);
    font-weight: 600;
}

.nav-logo {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px; /* provides spacing between the logo and the text */
}

.nav-logo-image {
    max-width: 400px; /* adjust as needed to control the size of the logo */
    max-height: 50px;
    height: auto;
}

.nav-logo-text {
    margin: 0;
    font-size: 1.5rem; /* adjust as needed */
}

.nav-links {
    display: flex;
    align-items: center;
    flex: 2;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: 25px;
}

.nav-links li {
    display: inline;
}

.nav-links a {
    text-decoration: none;
    color: var(--text-color); 
    padding: 5px 15px;
    transition: color 0.3s ease, border-bottom 0.1s ease;
    position: relative; 
}

.nav-links a:hover {
    color: var(--hover-color); /* Pastel red on hover */
    border-bottom: 3px solid var(--hover-color); /* Thick underline */
}

.book-now-btn {
    background-color: var(--btn-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.book-now-btn:hover {
    background-color: var(--btn-hover-color); 
}

.book-now-btn-mobile {
    background-color: var(--btn-color);
    color: white;
    padding: 3px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 20px;
    font-size: 12px;
    justify-content: center;
    text-align: center;
    text-decoration: none;
}

.book-now-btn-mobile:hover {
    background-color: var(--btn-hover-color); 
    text-decoration: none;
    color:white;
}

.menu-icon {
    display: none;
    cursor: pointer;
    flex-direction: column;
    gap: 4px;
}

.menu-icon div {
    width: 25px;
    height: 3px;
    background-color: var(--menu-icon-color);
    transition: transform 0.3s ease;
}

.menu-icon.active div:nth-child(1) {
    transform: rotate(45deg) translateY(7px);
}

.menu-icon.active div:nth-child(2) {
    opacity: 0;
}

.menu-icon.active div:nth-child(3) {
    transform: rotate(-45deg) translateY(-7px);
}

.mobile-only {
    display: none !important;
}

@media screen and (max-width: 890px) {
    .mobile-only {
        display: block !important;
    }

    .navbar {
        flex-direction: row;
    }

    .nav-logo {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav-links {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: var(--navbar-bg);
        box-shadow: 0px 4px 15px var(--mobile-box-shadow-color);
        display: none;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 10px 0;
    }

    .nav-links.open {
        display: flex;
    }

    .nav-logo-text {
        width: 150px;
    }

    .nav-links a {
        display: block;
        width: 100%;
        text-align: center;
        padding: 10px 0;
        border-bottom: none !important; /* Override the underline on mobile */
    }

    .book-now-btn {
        display: none;
    }

    .menu-icon {
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 370px) {
    .book-now-btn-mobile {
        display: none !important;
    }
}