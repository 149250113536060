.faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    border-radius: 8px;
    background: #f9f9f9;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.faq-title {
    margin-bottom: 30px;
    text-align: center;
    font-size: 4rem;
    font-weight: 900;
    color: #333;
}

.faq-list {
    list-style: none;
    padding: 0;
}

.faq-item {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
}

.faq-item.active .faq-answer {
    max-height: 1000px;
    transition: max-height 0.4s ease-in;
}

.faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    cursor: pointer;
    font-weight: 700;
    user-select: none;
}

.faq-question:hover {
    background: #f1f1f1;
}

.faq-icon {
    line-height: 1;
    font-size: 1.2rem;
    font-weight: bold;
    transition: transform 0.3s ease;
}

.faq-item.active .faq-icon {
    transform: rotate(180deg);
}

.faq-answer {
    /* max-height: 0; Remove this as we're now controlling visibility with display */
    display: none; /* Add this to hide by default */
    overflow: hidden;
    /* transition: max-height 0.4s ease-out; Remove this as it won't work with display */
    padding: 0 20px;
    color: #595959;
}

.faq-item.active .faq-answer {
    /* max-height: 1000px; Remove this */
    display: block; /* Add this to show when active */
    /* transition: max-height 0.4s ease-in; Remove this */
}

.faq-answer p {
    margin: 15px 0;
    line-height: 1.6;
}

.anchor {
    display: block;
    height: 120px; /* this value can be adjusted based on the height of your fixed header */
    margin-top: -120px; /* negative value of the height to pull the element back up */
    visibility: hidden;
    position: relative;
    z-index: -1;
}

/* For responsive design */
@media (max-width: 600px) {
    .faq-container {
        padding: 20px; /* Reduce padding for more space */
    }

    .faq-title {
        font-size: 1.75rem; /* Adjust title size for smaller screens */
    }

    .faq-question, .faq-answer {
        padding: 12px 15px; /* Adjust padding for questions and answers */
    }

    .faq-answer p {
        margin: 10px 0;
        font-size: 1rem; /* Make sure the font size is legible */
    }

    .faq-question {
        font-size: 1.1rem; /* Increase font size for questions */
        text-align: center; /* Center the question text */
        font-weight: 700; /* Make question text even bolder */
    }

    .faq-icon {
        margin-left: auto; /* Push the icon to the right */
    }
}
