:root {
    --navbar-bg: #ffffff;
    --text-color: #333;
    --hover-color: #FF5A5A;
    --hover-bg-color: #FF7878;
    --btn-color: #FF5A5A;
    --btn-hover-color: #d61b1b;
    --box-shadow-color: rgba(0, 0, 0, 0.05);
    --menu-icon-color: #333;
    --mobile-box-shadow-color: rgba(0, 0, 0, 0.1);
}

body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    overflow-x: hidden;
    background-color: #f8f9fa;
}
html {
    overflow-x: hidden;
}

.homepage-container {
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

/* Hero Section */
.hero {
    position: relative;
    height: 500px;
    overflow: hidden;
}

.hero img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero h1, 
.hero p {
    position: absolute;
    bottom: 50px;
    left: 50px;
    margin: 0;
}

.hero h1 {
    font-size: 2.5em;
    color: #fff;
}

.hero p {
    font-size: 1.2em;
    color: #fff;
}

.button, 
button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.button:hover, 
button:hover {
    background-color: #0056b3;
}

/* Our Team Section */
.our-team {
    padding: 50px 0;
}

.team-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.team-member {
    flex-basis: calc(33.33% - 20px);
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
    text-align: center;
}

.team-member img {
    max-width: 100%;
    height: auto;
}

/* FAQs Section */
.faqs {
    padding: 50px 0;
    background-color: #f9f9f9;
}

.faq-item:not(:last-child) {
    margin-bottom: 20px;
}

/* CTA Sections */
.services-cta, 
.testimonials-cta, 
.contact-cta {
    padding: 50px 0;
    text-align: center;
}

.services-cta a, 
.testimonials-cta a {
    display: inline-block;
    margin-top: 20px;
}

/* Footer */
footer {
    background-color: #333;
    color: #fff;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
}

footer h3 {
    margin-top: 0;
}

footer ul {
    list-style: none;
    padding: 0;
}

footer li {
    margin-bottom: 10px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .team-member {
        flex-basis: 100%;
    }

    .hero h1 {
        font-size: 2em;
    }

    .hero p {
        font-size: 1em;
    }

    footer {
        flex-direction: column;
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--btn-color);
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--btn-hover-color);
  }