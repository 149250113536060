.services {
    background-color: #f8f9fa;
}

/* General Services Page Styles */
.services-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    z-index: 3;
}

.services-content {
    flex: 1;  /* Allows the content to take up the available space */
    padding: 2rem;
    background-color: white; /* Light grey background */
    justify-content: center;
    text-align: center;
}

.service-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.services-title {
    background-color: var(--btn-color);
    color: white;
    padding: 5px 0px;
    max-width: 250px;
    margin: auto;
    border-radius: 25px;
    -webkit-box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.5);
}

.paediatric-title {
    background-color: var(--btn-color);
    color: white;
    padding: 5px 0px;
    max-width: 600px;
    margin: auto;
    margin-top: 105px;
    border-radius: 25px;
    -webkit-box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.5);
}

.service-cta-button {
    display: block;
    width: auto;
    max-width: 150px;
    margin: 20px auto; /* Top and bottom margin for spacing, auto horizontal margins for centering */
    text-align: center; /* Centers the text inside the button */
    background-color: var(--btn-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.service-cta-button:hover {
    background-color: var(--btn-hover-color);
    color:white;
    font-weight: 500;
}

.service-description {
    color: #555;
    font-size: 1.2rem;
    text-align: center;
    margin: 0 auto;
    max-width: 80%;
}

.service-image {
    max-width: 80%;
    display: block;
    margin: 0 auto;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.5);
}

.anchor {
    display: block;
    height: 120px; /* this value can be adjusted based on the height of your fixed header */
    margin-top: -120px; /* negative value of the height to pull the element back up */
    visibility: hidden;
    position: relative;
    z-index: -1;
}

@media (max-width: 768px) {
    .service-title, .service-description {
        text-align: center;
    }

    .service-description {
        max-width: 100%;
    }
}
