.services-banner {
    position: relative;
    width: 100%;
    margin-top:70px;
    max-height: 250px;
    overflow: hidden;
}

.banner-image {
    width: 100%;
}

.banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 2.5rem;
}
