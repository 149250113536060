.about-us-container {
    display: flex;
    flex-direction: column;
}

/* Banner */
.about-us-banner {
    position: relative;
    width: 100%;
    max-height: 200px;
    overflow: hidden;
}

.banner-image {
    width: 100%;
    height: auto;
}

/* Content Styles */
.about-us-content {
    padding: 3rem 2rem;
}

.about-us-row {
    align-items: center; /* Aligns items vertically centered */
}

.about-us-col {
    padding: 1rem;
    box-sizing: border-box;
}

.about-us-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.about-us-desc {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
}

.about-us-img {
    max-width: 100%;
    height: auto; /* Adjust height to be automatic */
    border-radius: 105px;
    -webkit-box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.5);
    object-fit: cover; /* Cover the whole area without distortion */
}

/* Responsive styles */
@media (max-width: 768px) {
    .about-us-row {
        flex-direction: column;
    }

    .about-us-col {
        padding: 0.5rem;
    }
    .about-us-img {
        height: auto; /* Remove fixed height to maintain aspect ratio */
        width: 100%; /* Make sure it's taking full width of its parent */
    }
}
